@import url('https://fonts.cdnfonts.com/css/circular-std');

@tailwind base;

/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #6585e8 transparent;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 6px;
}

*::-webkit-scrollbar-track {
  border-radius: 5px;
}

*::-webkit-scrollbar-thumb {
  background-color: #6585e8;
  border-radius: 14px;
  /* border: 3px solid #6585e8; */
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

@tailwind components;
@tailwind utilities;

.primary-gradient {
  background: linear-gradient(165deg, #e90dda -1.38%, #15cff1 73.87%);
}

body {
  background-color: #f5f7fb;
  font-family: 'Circular Std', sans-serif;
  /* overflow: hidden; */
}

#root {
  height: 100%;
}

loading-indicator:before {
  content: "";
  background: #00000017;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
}

.loading-indicator:after {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 50px;
  height: 50px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  text-align: center;
  border: 5px solid #15CFF1;
  border-top-color: #E90DDA;  
  z-index: 1001;
  animation: spinner .6s linear infinite;
}

@keyframes spinner {
  to {transform: rotate(360deg);}
}